import axios from 'axios';
import { updateUtagData } from 'client/common/components/YourNeimans/components/utilities';
import { getJwtToken, getUcaId } from './amplifyUtils';

export const callSummaryData = async (
  fastlyHost,
  ucaId,
  body,
  headers,
  tierNames) => {
  try {
    const response = await axios.post(`${fastlyHost}/loyalty/api/v1/profile/ucaId/${ucaId}/summary`, body, { headers });
    const { data } = response;

    updateUtagData({
      loyalty_member_status: 'true',
      // ...(data?.memberType !== undefined
      //   && { loyalty_member_type: data.memberType }),
      loyalty_tier: tierNames[data?.tierCode],
    });

    const sessionDataLoyalty = {
      loyalty_member_status: 'true',
      // ...(data?.memberType !== undefined
      //   && { loyalty_member_type: data.memberType }),
      loyalty_tier: tierNames[data?.tierCode],
    };

    const sessionDataString = JSON.stringify(sessionDataLoyalty);
    window.sessionStorage.setItem('loyalty_info', sessionDataString);
  } catch (error) {
    console.error(error);
  }
};

export const getSummaryData = async (user, updateUtag) => {
  const tierNames = {
    CIRCLE_1: 'Circle One',
    CIRCLE_2: 'Circle Two',
    CIRCLE_3: 'Circle Three',
    CIRCLE_4: 'Circle Four',
    CIRCLE_5: 'Circle Five',
    CIRCLE_6: 'Circle Six',
    PRESIDENT: 'President\'s Circle',
    CHAIRMAN: 'Chairman\'s Circle',
  };

  const ucaId = await getUcaId(user);
  const jwtToken = await getJwtToken(user);

  const lambdaHost = window.sessionStorage.getItem('lambdaHost');
  const fastlyHost = window.sessionStorage.getItem('fastlyUCAHost') || lambdaHost;

  const loyaltyProfileId = user.attributes['custom:loyaltyProfileId'];

  const body = {
    ucaId,
    loyaltyProfileId,
  };

  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  };
  updateUtag({
    loyalty_member_status: 'false',
    // loyalty_member_type: 'none',
    loyalty_tier: 'none',
  });

  const sessionDataLoyalty = {
    loyalty_member_status: 'false',
    // loyalty_member_type: 'none',
    loyalty_tier: 'none',
  };

  const sessionDataString = JSON.stringify(sessionDataLoyalty);

  window.sessionStorage.setItem('loyalty_info', sessionDataString);

  await callSummaryData(fastlyHost, ucaId, body, headers, tierNames);
};
